import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { CustomEase } from "gsap/CustomEase";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import Lottie from "./Lottie";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CSSRulePlugin);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollToPlugin);

Lottie();

jQuery(document).ready(function ($) {
	consoleMessage: {
		console.log(
			"%cCreated by %cTWK%cwww.thewebkitchen.co.uk",
			"background: #13212E; color: #FFFFFF; padding: 5px 0px 5px 10px; margin: 25px 0px;",
			"background: #13212E; color: #05E5C8; padding: 5px 10px 5px 0px; font-weight: bold;",
			"background: #FFFFFF; padding: 5px 10px;"
		);
	}

	modernizer: {
		if (!Modernizr.objectfit) {
			$(".object-fit").each(function () {
				var $container = $(this),
					imgUrl = $container.find("img").prop("src");
				if (imgUrl) {
					$container
						.css("backgroundImage", "url(" + imgUrl + ")")
						.addClass("not-compatible");
				}
			});
		}
	}

	sixthForm: {
		if ($(".sf-banner__title-changing").length) {
			const changingTexts = $(".sf-banner__title-changing span");
			const totalTexts = changingTexts.length;

			if (totalTexts > 1) {
				// Initialize the timeline
				const textTimeline = gsap.timeline({
					repeat: -1,
					repeatDelay: 0.5,
				});

				// Set initial state - hide all spans except first one using clipPath
				gsap.set(changingTexts, {
					clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
				});

				// Loop through each text span
				changingTexts.each(function (index) {
					const currentText = $(this);
					const nextText =
						index < totalTexts - 1
							? changingTexts.eq(index + 1)
							: changingTexts.eq(0);

					// Animation for current text exiting
					textTimeline.to(currentText, {
						duration: 0.5,
						clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
						ease: "power2.in",
					});

					// Animation for next text entering
					textTimeline.to(nextText, {
						duration: 0.5,
						clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
						ease: "power2.out",
					});

					// Pause between words
					textTimeline.to({}, { duration: 2 });
				});
			}
		}

		// Sticky link - changes color on scroll when reaches [data-name="full-page-slider-with-videos"]
		const stickyLink = document.querySelector('[data-name="sticky-button"]');
		const firstSection = document.querySelector('[data-name="full-page-slider-with-videos"]');

		if (stickyLink && firstSection) {
			ScrollTrigger.create({
				trigger: firstSection,
				start: "top bottom-=100px",
				end: "top top",
				scrub: true,
				//markers: true,
				onEnter: () => {
					gsap.to(stickyLink, { backgroundColor: "#FFCF00", duration: 0.3	});
					gsap.to( stickyLink.querySelector('a'), { color: "#000000", duration: 0.3 } );
					gsap.to( stickyLink.querySelectorAll('svg path'), { stroke: "#000000", duration: 0.3 } );
				},
				onLeaveBack: () => {
					gsap.to(stickyLink, { backgroundColor: "#7141FB", duration: 0.3	});
					gsap.to( stickyLink.querySelector('a'), { color: "#FFFFFF", duration: 0.3 } );
					gsap.to( stickyLink.querySelectorAll('svg path'), { stroke: "#FFFFFF", duration: 0.3 } );
				}
			});
		}

		// Add hover animations for sixth form areas
		if ($(".sf-areas__item").length && window.innerWidth > 1280) {
			$(".sf-areas__item").each(function () {
				const video = $(this).find(".sf-areas__video");

				$(this).on("mouseenter", function () {
					// Expand the hovered item
					gsap.to($(this), {
						width: "37%",
						duration: 0.3,
						ease: "power2.out",
					});

					// Shrink all other items
					gsap.to($(".sf-areas__item").not(this), {
						width: "21%",
						duration: 0.3,
						ease: "power2.out",
					});
					gsap.to($(this).find(".sf-areas__discover"), {
						opacity: 1,
						duration: 0.3,
						ease: "power2.out",
					});

					// Show the video if it exists and plays it.
					if (video.length) {
						gsap.to(video, {
							opacity: 1,
							duration: 0.3,
							ease: "power2.out",
						});

						video[0].play();
					}
				});

				$(this).on("mouseleave", function () {
					// Reset all items to original width
					gsap.to($(".sf-areas__item"), {
						width: "25%",
						duration: 0.3,
						ease: "power2.out",
					});
					gsap.to($(this).find(".sf-areas__discover"), {
						opacity: 0,
						duration: 0.3,
						ease: "power2.out",
					});

					// Hide the video if it exists and pauses it.
					if (video.length) {
						gsap.to(video, {
							opacity: 0,
							duration: 0.3,
							ease: "power2.out",
						});

						video[0].pause();
					}
				});
			});
		}

		// Add twist slider pin effect with fade out and scale down
		if ($(".sf-twist-slider__item").length) {
			let sliderItems = $(".sf-twist-slider__item");

			// Create a scroll effect where each slide pins until the next one scrolls in
			sliderItems.each(function (index) {
				// Set alternating transform origin
				const origin = index % 2 === 0 ? "top left" : "top right";
				const rotation = index % 2 === 0 ? 10 : -10;

				// Don't create a pin for the last item since there's no next slide to scroll over it
				if (index < sliderItems.length - 1) {
					// Create a timeline for the fade and scale effect
					const tl = gsap.timeline({
						scrollTrigger: {
							trigger: this,
							start: "top top",
							end: "bottom top",
							// endTrigger: sliderItems.eq(index + 1),
							pin: true,
							pinSpacing: false,
							scrub: true,
							//markers: true,
						},
					});

					// Add the scale down and fade out animations to the timeline
					// These will automatically be tied to scroll progress
					tl.to(this, {
						scale: 0.3,
						opacity: 0,
						rotation: rotation,
						ease: "none",
						transformOrigin: origin,
					}); // Start the animation 70% through the timeline
				}
			});

			// Add click handlers for the pagination dots
			$(".sf-twist-slider__dot").on("click", function () {
				// Get the slide index from the data attribute
				const slideIndex = $(this).data("slide");
				console.log(slideIndex);
				// Get the target slide
				const targetSlide = $(
					".sf-twist-slider__item [data-slide=" + slideIndex + "]"
				);
				console.log(targetSlide.offset().top);
				// Scroll to the target slide with smooth animation
				$("html, body").animate(
					{
						scrollTop: window.innerHeight * (slideIndex + 1),
					},
					300
				);
			});
		}

		if ($(".sf-meet-people__slider").length) {
			const $slider = $(".sf-meet-people__slider");

			// Bind the events before initialization
			$slider.on('init', function() {
				setupSliderAnimation()
			});

			$slider.slick({
				dots: false,
				arrows: false,
				infinite: true,
				speed: 500,
				slidesToShow: 3,
				slidesToScroll: 1,
				initialSlide: 0,
				swipeToSlide: true,
				centerMode: true,
				centerPadding: '100px',
				cssEase: 'linear',
				responsive: [
					{
						breakpoint: 1441,
						settings: {
							slidesToShow: 3,
							centerPadding: '100px',
							margin: 30,
						}
					},
					{
						breakpoint: 991,
						settings: {
							slidesToShow: 2,
							centerPadding: '60px',
							margin: 20,
						},
					},
					{
						breakpoint: 767,
						settings: {
							slidesToShow: 1,
							centerPadding: '40px',
							margin: 15,
						},
					},
				],
			});

			// Update counter
			function updateCounter() {
				const currentSlide = $slider.slick("slickCurrentSlide") + 1;
				const totalSlides = $slider.slick("getSlick").slideCount;
				$(".sf-meet-people__counter").text(`${currentSlide} of ${totalSlides}`);
			}

			// Initialize counter
			updateCounter();

			// Add click handlers for custom navigation
			$(".sf-meet-people__prev").on("click", function () {
				$slider.slick("slickPrev");
				updateCounter();
			});

			$(".sf-meet-people__next").on("click", function () {
				$slider.slick("slickNext");
				updateCounter();
			});

			// Update counter on slide change
			$slider.on("afterChange", function () {
				updateCounter();
			});

			// Add scroll animation for the slider
			function setupSliderAnimation() {
				// Get all slides
				const slides = $slider.find(".slick-slide");
				// Skip if there are less than 3 slides
				if (slides.length < 3) return;

				// Slick is not reliable for centerMode, so we find the center slide manually.
				function findCenterSlide() {
					const viewportCenter = window.innerWidth / 2;
					let closestSlide = null;
					let closestDistance = Infinity;
					
					// Get all slides
					const slides = $slider.find('.slick-slide');
					
					slides.each(function() {
						const slideRect = this.getBoundingClientRect();
						const slideCenter = slideRect.left + (slideRect.width / 2);
						const distanceToCenter = Math.abs(viewportCenter - slideCenter);
						
						if (distanceToCenter < closestDistance) {
							closestDistance = distanceToCenter;
							closestSlide = $(this);
						}
					});
					
					return closestSlide ? closestSlide.data('slick-index') : null;
				}

				const centerIndex = findCenterSlide();
				const centerSlide = slides.filter(`[data-slick-index="${centerIndex}"]`);

				// Get all slides before and after center
				const prevSlides = slides.filter((i, el) => $(el).data('slick-index') < centerIndex);
				const nextSlides = slides.filter((i, el) => $(el).data('slick-index') > centerIndex);

				// Calculate animation values based on distance from center
				function getSlideAnimation(distance, isNext) {
					const rotation = isNext ? 10 : -10;
					const xOffset = (distance * 20) * (isNext ? 1 : -1); // Increases with distance
					const yOffset = 60 + (distance * 20); // Increases with distance
					
					return {
						initial: {
							rotation: rotation,
							yPercent: yOffset,
							xPercent: xOffset
						},
						final: {
							rotation: 0,
							yPercent: 0,
							xPercent: 0
						}
					};
				}

				// Set initial states
				gsap.set(centerSlide, { rotation: 0, yPercent: 60, xPercent: 0 });

				// Convert prevSlides to array to get total count
				const prevSlidesArray = prevSlides.get();
				const totalPrevSlides = prevSlidesArray.length;

				// Iterate in reverse order
				$(prevSlidesArray).each(function(i) {
					// Reverse the distance calculation (totalPrevSlides - i ensures closest to middle gets smallest number)
					const distance = totalPrevSlides - i;
					const animation = getSlideAnimation(distance, false);
					gsap.set($(this), animation.initial);
				});

				nextSlides.each(function(i) {
					const animation = getSlideAnimation(i + 1, true);
					gsap.set($(this), animation.initial);
				});

				// Create the scroll animation
				const tl = gsap.timeline({
					scrollTrigger: {
						trigger: $slider,
						start: "top 80%",
						end: "top 30%",
						scrub: true,
					}
				});

				// Animate center slide
				tl.to(centerSlide, { rotation: 0, yPercent: 0, xPercent: 0, duration: 0.5 }, 0);

				// Animate all slides simultaneously
				tl.to([...prevSlides, ...nextSlides], { 
					yPercent: 0, 
					xPercent: 0, 
					duration: 0.7,
					rotation: 0
				}, 0.2);

				/* The hover animation */
				const sliderItems = document.querySelectorAll('.sf-meet-people__item')
				if ( sliderItems.length > 0 ) {
					sliderItems.forEach(item => {
						let slideBg = item.querySelector('.sf-meet-people__content__bg')
						let slideText = item.querySelector('.sf-meet-people__text')

						gsap.set( slideBg, { rotation: -20, yPercent: 120, x: -50 } )
						gsap.set( slideText, { height: 0, alpha: 0 } )

						//gsap.to( slideBg, { rotation: 0, yPercent: 0, x: 0, duration: 0.5, ease: "power1.out" } )
						//gsap.to( slideText, { height: 'auto', alpha: 1, duration: 0.5, ease: "power1.out" } )
							
						item.addEventListener('mouseenter', () => {
							gsap.to( slideBg, { rotation: 0, yPercent: 0, x: 0, duration: 0.5, ease: "power1.out" } )
							gsap.to( slideText, { height: 'auto', alpha: 1, duration: 0.5, ease: "power1.out" } )
						})
						item.addEventListener('mouseleave', () => {
							gsap.to( slideBg, { rotation: -20, yPercent: 120, x: -50, duration: 0.5, ease: "power1.out" } )
							gsap.to( slideText, { height: 0, duration: 0.5, alpha: 0, ease: "power1.out" } )
						})
					})
				}
			}
		}
	}

	sixthFormStats: {
		const inNumbers = document.querySelector('[data-name="hampton-in-numbers"]')

		if ( inNumbers ){
			const stats = inNumbers.querySelectorAll('.circle-stats')

			if ( stats.length > 0 )  {
				// animate the numbers to count up
				stats.forEach( stat => {
					const number = stat.querySelector('.circle-stats__number span:first-child')
					const numberValue = number.innerHTML
					gsap.from(number, {
						innerHTML: 0,
						scrollTrigger: {
							trigger: inNumbers,
							start: "top 80%",
							end: "top 50%",
							scrub: false,
							once: true,
							onEnter: () => {
								gsap.to(number, { innerHTML: numberValue, duration: 2, ease: "power1.out", snap: "innerHTML" });
							}
						}
					});
				})
			}
		}
	}

	// fixedheader: {
	// 	$(function () {
	// 		$(window).scroll(function () {
	// 			if ($(window).scrollTop() >= 150) {
	// 				$('.header').addClass('fixed-header');
	// 			}
	// 			if ($(window).scrollTop() >= 160) {
	// 				$('.header').addClass("transform-none");
	// 			}
	// 			else {
	// 				$('.header').removeClass('fixed-header');
	// 				$('.header').removeClass("transform-none");
	// 			}
	// 		});
	// 	});
	// }
	// Preloader
	function preloader() {
		// console.log('proloader');
		var body = $("body");

		body.addClass("no-scroll");

		var preloader = $(".js-preloader");
		var preloaderLine = $(".js-preloader-line");
		var bannerSmallTitle = $(".banner .container h1 span.small");
		var bannerLargeTitle = $(".banner .container h1 span.large");
		// var bannerbottom = $('.js-bottom-nav');

		var preloaderTimeline = gsap.timeline({
			onComplete: () => {
				body.removeClass("no-scroll");
			},
		});

		preloaderTimeline
			.to(preloaderLine, {
				x: 0,
				duration: 2.5,
				ease: "power4.out",
			})
			.to(preloader, {
				x: "100%",
				duration: 1,
				ease: "power4.out",
			})
			.from(
				bannerSmallTitle,
				{
					opacity: 0,
					y: "40px",
					duration: 0.3,
					ease: "power4.out",
				},
				"-=0.75"
			)
			.from(
				bannerLargeTitle,
				{
					opacity: 0,
					y: "40px",
					duration: 0.3,
					ease: "power4.out",
				},
				"-=0.3"
			);
	}

	function drawCanvas(canvas, ctx, translate, scale, wordsPath) {
		ctx.save();
		ctx.beginPath();
		ctx.fillStyle = "#FFFFFF";
		ctx.rect(0, 0, canvas.width, canvas.height);
		ctx.fill();
		ctx.globalCompositeOperation = "xor";
		ctx.beginPath();
		ctx.translate(translate.x, translate.y);
		ctx.scale(scale.x, scale.y);
		ctx.fill(wordsPath);
		ctx.restore();
	}

	function homeBannerCanvas() {
		let canvas = document.querySelector(".js-home-banner-canvas");
		// console.log(canvas);
		let ctx = canvas.getContext("2d");

		let svg = document.querySelector(".banner__canvas .hidden-svg svg");
		let svgPath = document.querySelector("path", svg).getAttribute("d");
		let wordsPath = new Path2D(svgPath);

		// Client HP laptop size
		console.log(document.body.clientWidth);
		console.log(window.innerHeight);
		if (
			window.innerHeight < 900 &&
			window.innerHeight > 450 &&
			document.body.clientWidth > 1025 &&
			document.body.clientWidth < 1440
		) {
			var initialScale = {
				x: 0.5,
				y: 0.5,
				yOffset: 30,
			};
		} else if (
			window.innerHeight < 900 &&
			window.innerHeight > 450 &&
			document.body.clientWidth > 1439
		) {
			var initialScale = {
				x: 0.75,
				y: 0.75,
				yOffset: 30,
			};
		} else if (document.body.clientWidth < 500) {
			var initialScale = {
				x: 0.25,
				y: 0.25,
				yOffset: 30,
			};
		} else if (document.body.clientWidth < 769) {
			var initialScale = {
				x: 0.5,
				y: 0.5,
				yOffset: 30,
			};
		} else if (document.body.clientWidth < 1025) {
			var initialScale = {
				x: 0.65,
				y: 0.65,
				yOffset: 30,
			};
		} else if (document.body.clientWidth < 2250) {
			var initialScale = {
				x: 1,
				y: 1,
				yOffset: 30,
			};
		} else {
			var initialScale = {
				x: 1.5,
				y: 1.5,
				yOffset: 30,
			};
		}
		// }
		var svgWidth = 1193;
		// var svgWidth = parseInt(svg.clientWidth);
		var svgHeight = 397;
		// var svgHeight = parseInt(svg.clientHeight);
		var scale = {
			x: initialScale.x,
			y: initialScale.y,
		};

		// console.log({svgWidth, svgHeight});
		// console.log(initialScale);
		let finalScale = 10;

		canvas.width = window.innerWidth;
		canvas.height = window.innerHeight;

		let translate = {
			x: window.innerWidth / 2 - (svgWidth * initialScale.x) / 2,
			y:
				window.innerHeight / 2 -
				(svgHeight * initialScale.y) / 2 +
				initialScale.yOffset,
		};

		drawCanvas(canvas, ctx, translate, scale, wordsPath);
		$(".js-home-banner").each(function () {
			let trigger = $(this);
			var logoCrest = $(".js-logo-crest");
			var logoWordmark = $(".js-logo-wordmark");
			var welcomeText = $(".js-home-welcome");
			var homeHamburger = $(".js-home-hamburger");
			var rowOne = $(".js-banner-row-1");
			var rowTwo = $(".js-banner-row-2");
			var cursor = $(".canvas-cursor");

			var positionLogo = document
				.querySelector(".js-logo-wordmark")
				.getBoundingClientRect();

			let homeVideoTimeline = gsap.timeline({
				ease: "power2.in",
				scrollTrigger: {
					scrub: 1.5,
					anticipatePin: false,
					start: "top",
					id: "ST",
					once: true,
					pin: trigger,
					trigger: trigger,
					markers: false,
					end: "+=" + window.innerHeight * 3,
					// onEnterBack: () => playAfterZoomTimeline.reverse(),
					// onLeave: deletePinSpacing ,
				},
			});
			//function deletePinSpacing() {
			//	const spacing = document.querySelector('.pin-spacer');
			//	var banner =  $('.js-home-banner');
			//	if(banner.parent().hasClass('pin-spacer')) {
			//		// banner.unwrap();
			//		// $('html, body').animate({
			//			// scrollTop: 0,
			//		// }, 20, "linear");
			//		// banner.css('transform', 'none');
			//	}
			//
			//}

			let playAfterZoomTimeline = gsap.timeline({
				ease: "power2.in",
				paused: true,
				onComplete: stopScrollBackUp,
			});

			playAfterZoomTimeline
				.from(logoWordmark, {
					opacity: 0,
					duration: 0.5,
					onStart: () => {
						$("body").addClass("no-scroll");
						setTimeout(function () {
							$("body").removeClass("no-scroll");
						}, 1000);
					},
				})
				.to(
					homeHamburger,
					{
						opacity: 1,
						duration: 0.3,
					},
					"<"
				)
				.from(
					rowTwo,
					{
						opacity: 0,
						height: 0,
						duration: 0.3,
					},
					"<"
				);
			// .to($('.pin-spacer'), {
			// 	y: window.innerHeight * -3,
			// 	onComplete: () => $(window).scrollTop(0)
			// })

			homeVideoTimeline
				.to(rowOne, {
					opacity: 0,
					duration: 0.04,
				})
				.to(
					logoCrest,
					{
						opacity: 0,
						duration: 0.04,
					},
					"<"
				)
				.to(
					homeHamburger,
					{
						opacity: 0,
						duration: 0.04,
					},
					"<"
				)
				.to(
					welcomeText,
					{
						opacity: 0,
						duration: 0.04,
					},
					"<"
				)
				.to(
					cursor,
					{
						opacity: 0,
						duration: 0.04,
					},
					"<"
				)
				.to(
					initialScale,
					{
						x: 40,
						y: 40,
						yOffset: 3867,
						duration: 3.5,
						opacity: 0,
						// ease: CustomEase.create("custom", "M0,0,C0.238,0,0.43,0.071,0.486,0.114,0.592,0.196,1,0.636,1,1"),
						ease: CustomEase.create(
							"custom",
							"M0,0 C0.222,0.054 0.338,0.092 0.4,0.132 0.514,0.206 1,0.636 1,1 "
						),
						onUpdate: () => {
							let translate = {
								x: window.innerWidth / 2 - (svgWidth * initialScale.x) / 2,
								y:
									window.innerHeight / 2 -
									(svgHeight * initialScale.y) / 2 +
									initialScale.yOffset,
							};
							let scale = { x: initialScale.x, y: initialScale.y };
							drawCanvas(canvas, ctx, translate, scale, wordsPath);
						},
					},
					"<"
				)
				.to(
					canvas,
					{
						opacity: 0,
						duration: 0.2,
						onStart: () => {
							playAfterZoomTimeline.play();
						},
					},
					">-0.5"
				)
				.to({}, { duration: 0.5 });

			//play animation on button click
			let topScroll = window.innerHeight * 3;

			let playButton = document.querySelector(".js-button-home-1");
			playButton.addEventListener("click", function () {
				$("html, body").animate(
					{
						scrollTop: topScroll,
					},
					1000,
					"linear"
				);
			});
			// fade out logo after scroll
			window.addEventListener("scroll", function () {
				// if($(window).scrollTop() >= (topScroll + 400)) {
				// 	gsap.to($('.js-logo-wordmark'), {opacity: 0, duration: 0.5});
				// }
				// } else {
				// 	gsap.to($('.js-logo-wordmark'), {opacity: 1, duration: 0.5});
				// }
			});

			// resize canvas
			window.addEventListener("resize", function () {
				if (
					window.innerHeight < 900 &&
					window.innerHeight > 450 &&
					document.body.clientWidth > 1025 &&
					document.body.clientWidth < 1440
				) {
					var initialScale = {
						x: 0.5,
						y: 0.5,
						yOffset: 30,
					};
				} else if (
					window.innerHeight < 900 &&
					window.innerHeight > 450 &&
					document.body.clientWidth > 1439
				) {
					var initialScale = {
						x: 0.75,
						y: 0.75,
						yOffset: 30,
					};
				} else if (document.body.clientWidth < 500) {
					var initialScale = {
						x: 0.25,
						y: 0.25,
						yOffset: 30,
					};
				} else if (document.body.clientWidth < 769) {
					var initialScale = {
						x: 0.5,
						y: 0.5,
						yOffset: 30,
					};
				} else if (document.body.clientWidth < 1025) {
					var initialScale = {
						x: 0.65,
						y: 0.65,
						yOffset: 30,
					};
				} else if (document.body.clientWidth < 2250) {
					var initialScale = {
						x: 1,
						y: 1,
						yOffset: 30,
					};
				} else {
					var initialScale = {
						x: 1.5,
						y: 1.5,
						yOffset: 30,
					};
				}
				// }
				var svgWidth = 1193;
				// var svgWidth = parseInt(svg.clientWidth);
				var svgHeight = 397;
				// var svgHeight = parseInt(svg.clientHeight);
				var scale = {
					x: initialScale.x,
					y: initialScale.y,
				};

				// console.log({svgWidth, svgHeight});
				// console.log(initialScale);
				let finalScale = 10;

				canvas.width = window.innerWidth;
				canvas.height = window.innerHeight;

				let translate = {
					x: window.innerWidth / 2 - (svgWidth * initialScale.x) / 2,
					y:
						window.innerHeight / 2 -
						(svgHeight * initialScale.y) / 2 +
						initialScale.yOffset,
				};
				// canvas.width = window.innerWidth;
				// canvas.height = window.innerHeight;

				// let translate = {x: (window.innerWidth / 2) - (svgWidth * initialScale.x / 2), y: ((window.innerHeight / 2) - (svgHeight * initialScale.y / 2)) + 30}
				// let scale = {x: initialScale.x, y: initialScale.y};
				drawCanvas(canvas, ctx, translate, scale, wordsPath);

				$(".pin-spacer").css("width", "auto");
				$(".js-home-banner").css("width", "100%");
				$(".js-home-banner").css("max-width", "unset");
			});
			// loadingAnimations();
			function stopScrollBackUp() {
				ScrollTrigger.create({
					start: "top top",
					end: 99999,
					onUpdate: (self) => {
						var scroller = gsap.to(window, {
							duration: 0.02,
							fastScrollEnd: true,
							scrollTo: "#js-home-banner",
							paused: true,
							ease: "Power1.easeOut",
						});
						if (self.direction === -1) {
							if (window.scrollY < window.innerHeight * 3) {
								var scrollTo = $(".js-home-banner").offset().top;
								console.log(scrollTo);
								scroller.play();
							}
						}
					},
				});
			}
		});
	}

	//canvas cursor :
	// if(removeCursor == false) {
	var cursorPointed = document.querySelector(".canvas-cursor");
	if (cursorPointed) {
		const moveCursor = (e) => {
			const mouseY = e.clientY - 30;
			const mouseX = e.clientX - 80;

			cursorPointed.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0)`;
		};
		window.addEventListener("mousemove", moveCursor);
	}
	// }

	parallaxButtons: {
		// document.querySelectorAll('.circle-link').forEach((single) => {
		// 	const buttonMagnetic = $(single).find($('.circle-link__circle'));
		// 	const buttonDeco1 = $(single).find($('.circle-link__image'));
		// 	const buttonDeco2 = $(single).find($('.circle-link--yellow'));
		// 	const buttonText = $(single).find($('.circle-link__text'));
		// 	// $(single).height(buttonDeco1.height());
		// 	// buttonMagnetic.height(buttonDeco1.height());
		// 	buttonMagnetic.mouseleave(function(e){
		// 		// gsap.to(this, {duration: 0.4});
		// 		gsap.to(buttonDeco1, { duration: 0.5, scale:1, x: 0, y: 0, overwrite: true});
		// 		// gsap.to(buttonText, { duration: 0.5, scale:1, x: 0, y: 0, delay: 0.03, overwrite: true});
		// 		gsap.to(buttonDeco2,{ duration: 0.5, scale:1, x: 0, y: 0, delay: 0.08, overwrite: true});
		// 		// console.log('mouseleave');
		// 	  });
		// 	  buttonMagnetic.mouseenter(function(e){
		// 		buttonMagnetic.mousemove(function(e){
		// 			callParallax(e);
		// 			// console.log('mousemove');
		// 		});
		// 	  });
		// 	  function callParallax(e){
		// 		parallaxIt(e, buttonDeco1, 35, 0);
		// 		parallaxIt(e, buttonDeco2, 20, 0.08);
		// 		// parallaxIt(e, buttonText, 20, 0.03 );
		// 	  }
		// 	  function parallaxIt(e, target, movement, delay){
		// 		// var $this = buttonMagnetic;
		// 		var relX = e.pageX - buttonMagnetic.offset().left;
		// 		var relY = e.pageY - buttonMagnetic.offset().top;
		// 		const bMHeight = $(single).height();
		// 		const bMWidth = $(single).width();
		// 		gsap.to(target, {
		// 			duration: 0.7,
		// 			// x: 30,
		// 			x: (relX - bMWidth/2) / bMWidth * movement,
		// 			// y: 30,
		// 			y: (relY - bMHeight/2) / bMHeight * movement,
		// 			ease: "power2",
		// 		  	delay: delay,
		// 			// overwrite: true
		// 		});
		// 	  }
		// 	});
	}

	hoverPDF: {
		$(".pdf").append('<div class="yellow-circle"></div>');
	}

	// admissionsBannerVideo: {
	// 	if($('body').hasClass('page-template-tpl-hidden-sidebar')) {
	// 		if($('.js-play-banner-video').length) {
	// 			let video = document.querySelector(".js-video-2");
	// 			$('.js-video-2').pause();

	// 			$(this).click(() => {
	// 				$('.js-video-2').css("opacity", 1);
	// 				$('.js-video-2').play();
	// 			})
	// 		}
	// 	}
	// }

	mathsWizard: {
		$("svg.wizard").on("click", function () {
			//console.info('nose-click');

			$(".wizard-before").fadeOut("400");

			setTimeout(function () {
				$(".wizard-form").css("opacity", 1);
			}, 400);
			// $('.wizard-form').css('opacity', 1);
		});

		if ($(".wizard").length > 0) {
			var moveEyes = new gsap.timeline({
				repeat: -1,
				repeatDelay: 0.5,
				yoyo: true,
			});

			moveEyes
				.addLabel("moveeyes")
				.fromTo(
					$(".right-eye"),
					0.8,
					{ rotation: 0, transformOrigin: "center" },
					{ rotation: -140, transformOrigin: "center" },
					0.35,
					"moveeyes"
				)
				.fromTo(
					$(".left-eye"),
					0.8,
					{ rotation: 140, transformOrigin: "center" },
					{ rotation: 0, transformOrigin: "center" },
					0.35,
					"moveeyes"
				);

			$("svg.wizard").on("mouseenter", function () {
				var eyebrows = new gsap.timeline();
				eyebrows
					// .addLabel('moveeyebrows').fromTo($('#eyebrows'), 0.3, { y: 0, transformOrigin: 'center' }, { y: -8, transformOrigin: 'center' }, 'moveeyebrows').fromTo($('#cheeks'), 0.3, { y: 0, transformOrigin: 'center' }, { y: -2, transformOrigin: 'center' }, 'moveeyebrows')
					.fromTo(
						$("#moustache .left"),
						0.3,
						{ rotation: 0, transformOrigin: "right" },
						{ rotation: 16, transformOrigin: "right" },
						"moveeyebrows"
					)
					.fromTo(
						$("#moustache .right"),
						0.3,
						{ rotation: 0, transformOrigin: "left" },
						{ rotation: -16, transformOrigin: "left" },
						"moveeyebrows"
					)
					.addLabel("moustachedown")
					.fromTo(
						$("#rightear"),
						0.3,
						{ rotation: 0, transformOrigin: "bottom left" },
						{ rotation: 2, transformOrigin: "bottom left" },
						"moveeyebrows"
					)
					.addLabel("moustachedown")
					.fromTo(
						$("#leftear"),
						0.3,
						{ rotation: 0, transformOrigin: "bottom right" },
						{ rotation: -2, transformOrigin: "bottom right" },
						"moveeyebrows"
					)
					.addLabel("moustachedown")
					// .fromTo($('#eyebrows'), 0.7, { y: -8, transformOrigin: 'center' }, { y: 0, transformOrigin: 'center' })
					// .fromTo($('#cheeks'), 0.7, { y: -2, transformOrigin: 'center' }, { y: 0, transformOrigin: 'center' }, 'moustachedown')

					.fromTo(
						$("#moustache .left"),
						0.7,
						{ rotation: 16, transformOrigin: "right" },
						{ rotation: 0, transformOrigin: "right" },
						"moustachedown"
					)
					.fromTo(
						$("#moustache .right"),
						0.7,
						{ rotation: -16, transformOrigin: "left" },
						{ rotation: 0, transformOrigin: "left" },
						"moustachedown"
					)
					.fromTo(
						$("#rightear"),
						0.3,
						{ rotation: 2, transformOrigin: "bottom left" },
						{ rotation: 0, transformOrigin: "bottom left" },
						"moustachedown"
					)
					.fromTo(
						$("#leftear"),
						0.3,
						{ rotation: -2, transformOrigin: "bottom right" },
						{ rotation: 0, transformOrigin: "bottom right" },
						"moustachedown"
					);
			});

			$("svg.wizard").on("click", function () {
				//    var nosebounce = new TimelineMax();
				//nosebounce.to($('.wizard #nose'), 0.3, { scale: 1.2, transformOrigin: 'center', ease:Bounce.easeOut}).to($('.wizard #nose'), 0.3, { transformOrigin: 'center', scale: 1 });
				var wavewand = new gsap.timeline({ repeat: 4 });
				wavewand
					.fromTo(
						$("#wand-arm"),
						0.7,
						{ rotation: 0, transformOrigin: "right" },
						{ rotation: 8, transformOrigin: "right" }
					)
					.fromTo(
						$("#wand-arm"),
						0.7,
						{ rotation: 8, transformOrigin: "right" },
						{ rotation: 0, transformOrigin: "right" }
					);
			});
		}
	}
	search: {
		$(".search-icon").on("click", function (e) {
			$(".searchform").toggleClass("search-open");
			$(this).toggleClass("submit-zindex");
			$(".select-lang").removeClass("transform-height");
			$("#menu-main-menu-top, .lang_dropdown").fadeOut();
			$("#s").focus();
			setTimeout(function () {
				$(".close-search").fadeIn();
			}, 300);
		});
		$(".close-search").on("click", function (e) {
			$(this).fadeOut();
			$(".searchform").removeClass("search-open");
			$(".search-icon").removeClass("submit-zindex");
			$("#menu-main-menu-top, .lang_dropdown").fadeIn();
		});
	}

	menu: {
		// Open submenu on click
		$(".top-nav .menu-item-has-children > a").on("click", function (e) {
			e.preventDefault();
			var thisID = $(this).parent().attr("id");

			$(".menu-item-has-children").removeClass("sub-menu-open"); // reset
			$(".sub-menu-wrap").removeClass("sub-menu-open"); // reset
			$(".sub-menu-container").removeClass("mobile-z-index"); // reset
			$(".site-menu-wrapper > .site-menu").removeClass("no-scroll"); // reset

			$(".sub-menu").removeClass("sub-menu-open");
			$(".site-menu__lhs").removeClass("menu-open--zindex");

			$(this).closest(".menu-item-has-children").toggleClass("sub-menu-open");
			$(".sub-menu-container")
				.find($(".sub-" + thisID))
				.toggleClass("sub-menu-open");
			$(this).next(".sub-menu-wrap").toggleClass("sub-menu-open");
			$(".site-menu__lhs").toggleClass("menu-open--zindex");
			$(".sub-menu-container").toggleClass("mobile-z-index");
			$(".site-menu-wrapper > .site-menu").toggleClass("no-scroll"); // reset
		});
		// Open menu with tabs - accessibility
		$(".top-nav .sub-menu-wrap a").on("focusin", function () {
			$(this).closest(".menu-item-has-children").addClass("sub-menu-open");
			$(this).closest(".sub-menu-wrap").addClass("sub-menu-open");

			// console.log($(this));
		});
		$(".top-nav .sub-menu-wrap a").on("focusout", function () {
			$(this).closest(".menu-item-has-children").removeClass("sub-menu-open");
			$(this).closest(".sub-menu-wrap").removeClass("sub-menu-open");
		});
		// Close on click outside.
		$(document).on("click", function (e) {
			if (
				!$(e.target).parent().hasClass("menu-item") &&
				$(".sub-menu-wrap").has(e.target).length === 0
			) {
				$(".menu-item-has-children").removeClass("sub-menu-open");
				$(".sub-menu-wrap").removeClass("sub-menu-open");
				$(".sub-menu-container")
					.find($(".sub-menu"))
					.removeClass("sub-menu-open");
				$(".site-menu__lhs").removeClass("menu-open--zindex");
				setTimeout(function () {
					$(".sub-menu-container").removeClass("mobile-z-index");
					$(".site-menu-wrapper > .site-menu").removeClass("no-scroll"); // reset
				}, 500);
			}
		});
	}

	anchorlinks: {
		$(document).on("click", ".scroll-to", function (event) {
			event.preventDefault();

			$("html, body").animate(
				{
					scrollTop: $($.attr(this, "href")).offset().top,
				},
				500
			);
		});
		$("a > img").each(function () {
			$(this).parent().addClass("image-link-wrapper");
		});
	}

	externallinks: {
		$('a[href^="mailto:"]').each(function () {
			$(this).addClass("email-link");
		});

		$('a:not(.email-link):not([class^="magnific-"])').each(function () {
			var a = new RegExp("/" + window.location.host + "/");

			// if (!a.test(this.href)) {
			// 	$(this).click(function (event) {
			// 	event.preventDefault();
			// 	window.open(this.href, '_blank');
			// 	});
			// }
		});
		pdfs: {
			// Open PDFs in new window
			$(function () {
				$('a[href$=".pdf"]').prop("target", "_blank");
			});
		}
	}

	subMenuThird: {
		if (document.body.clientWidth < 460) {
			$(".sub-menu__third .sub-menu__third--page-list").css("display", "none");

			$(".sub-menu__third h6").on("click", function () {
				// $(this).siblings('.sub-menu__third--page-list').addClass('open');
				// $(this).siblings('.sub-menu__third--page-list').slideUp('fast');

				if (!$(this).siblings(".sub-menu__third--page-list").hasClass("open")) {
					$(this).siblings(".sub-menu__third--page-list").slideDown("fast");
					$(this).siblings(".sub-menu__third--page-list").addClass("open");
				} else {
					$(this).siblings(".sub-menu__third--page-list").slideUp("fast");
					$(this).siblings(".sub-menu__third--page-list").removeClass("open");
				}
			});
		}
	}
	slider: {
		$(".full-width-slider").slick({
			dots: true,
			arrows: false,
			fade: true,
			autoplay: true,
			autoplaySpeed: 4500,
		});

		$(".slider-multiple-items").slick({
			infinite: true,
			arrows: false,
			dots: true,
			slidesToShow: 2,
			slidesToScroll: 2,
			margin: 40,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		});

		$(".js-quote-slider").slick({
			slidesToShow: 1,
			rows: 2,
			arrows: true,
			appendArrows: $(".quote-slider__arrows"),
		});
		$(".js-info-video-slider").slick({
			slidesToShow: 1,
			// rows:3,
			arrows: true,
			adaptiveHeight: true,
			appendArrows: $(".info-slider__arrows"),
		});
		$(".js-co-curricular-slider").slick({
			slidesToShow: 1,
			// rows:3,
			arrows: true,
			adaptiveHeight: true,
			appendArrows: $(".arrows"),
		});
		$(".js-wwu-carousel").slick({
			slidesToShow: 1,
			dots: true,
			arrows: false,
			autoplay: false,
			autoplaySpeed: 6000,
		});
		$(".js-wwu-text-carousel").slick({
			slidesToShow: 1,
			dots: false,
			arrows: true,
			autoplay: false,
			autoplaySpeed: 6000,
		});
		$(".js-wwu--quote").slick({
			slidesToShow: 1,
			dots: false,
			arrows: true,
			autoplay: false,
			autoplaySpeed: 6000,
		});
		$(".js-carousel-blocka-slider").slick({
			slidesToShow: 1,
			// rows:3,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 8000,
			adaptiveHeight: true,
			appendArrows: $(".info-slider__arrows"),
		});
		$(".js-process-slider").slick({
			slidesToShow: 1,
			rows: 2,
			adaptiveHeight: true,
			arrows: true,
			// appendArrows: $('.process-slider__arrows'),
			dots: true,
			// appendDots: $('.process-slider__dots'),
		});

		var slider = $(".process-slider");

		var slickSlides = slider.find(".slick-slide");
		var slideHeights = [];

		var slidesInfo = $(".process-slider__info");
		var infoHeights = [];

		var arrows = $(".process-slider__arrows");
		var dots = $(".process-slider__dots");

		// DYNAMIC DOT POSITION
		//get array of heights of overall slick slides
		// slickSlides.each(function() {
		// 	var slideHeight = $(this).height();
		// 	slideHeights.push(slideHeight);
		// }) ;

		// DYNAMIC ARROW POSITION (Responsive)
		// get array of heights of slide info cards
		// slidesInfo.each(function() {
		// 	var infoHeight = $(this).height();
		// 	infoHeights.push(infoHeight);
		// }) ;

		// Set individual css "top" / "bottom" proprties of arrows and dots initial
		// arrow top is dependent on maximum of array of heights of slide info cards
		// dots bottom is dependent on first slide height
		// function setHeight() {
		// 	//arrows
		// 	var maxInfoHeight = Math.max(...infoHeights);
		// 	arrows.css({top: maxInfoHeight + 81,});
		// 	//dots

		// 	dots.css({bottom: slideHeights[1] - 35})
		// }
		// setHeight();
		// change position of dots dependent on the height of the slide so they always sit at same level relative to info box
		// $('.process-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		// 	dots.fadeTo("fast", 0.2);
		// 	dots.css({bottom: slideHeights[nextSlide + 1] - 35})
		// 	dots.fadeTo("slow", 1 );
		//   });

		$(".js-bio-slider").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			fade: true,
			autoplay: true,
			autoplaySpeed: 9000,
			speed: 200,
			dots: true,
			// adaptiveHeight: true,
			pauseOnHover: true,
			appendDots: $(".bio-slider__dots"),
			customPaging: function (slider, i) {
				var title = $(slider.$slides[i]).find("[data-title]").data("title");
				return '<div class="bio-slider__dots--name"> ' + title + " </div>";
			},
			cssEase: "cubic-bezier(0,-0.37, 0.3, 0.69)",
			responsive: [
				{
					breakpoint: 600,
					settings: {
						adaptiveHeight: true,
					},
				},
			],
		});
		// On before slide change
		$(".js-bio-slider").on(
			"beforeChange",
			function (event, slick, currentSlide, nextSlide) {
				var activeDots = this.parentElement.querySelectorAll(
					'.slick-dots [role="presentation"]'
				);
				var activeDot = activeDots[nextSlide];
				var line = this.parentElement.querySelector(".bio-slider__dots--line");

				if (window.innerWidth < 769) {
					var topPercent = "19.5%";
				} else {
					var topPercent = "30%";
				}
				line.style.width = "0%";
				setTimeout(() => {
					line.style.top =
						"calc(" + topPercent + " + " + activeDot.offsetTop + "px)";
				}, 450);
				setTimeout(() => {
					line.style.width = "150px";
				}, 750);
			}
		);
		$(".js-oh-slider").slick({
			infinite: true,
			slidesToShow: 2,
			initialSlide: 0,
			slidesToScroll: 1,
			arrows: true,
			draggable: false,
			appendArrows: $(".oh-slider__arrows"),
			responsive: [
				{
					breakpoint: 769,
					settings: {
						slidesToShow: 1,
					},
				},
			],
		});
		$(".js-featured-slider").slick({
			slidesToShow: 1,
			initialSlide: 0,
			slidesToScroll: 1,
			adaptiveHeight: false,
			arrows: false,
			dots: true,
			autoplay: true,
			autoplaySpeed: 6000,
			responsive: [
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 1,
					},
				},
			],
		});
		$(".js-whats-on-right-slider").slick({
			slidesToShow: 1,
			initialSlide: 0,
			slidesToScroll: 1,
			adaptiveHeight: false,
			arrows: false,
			dots: true,
			autoplay: true,
			autoplaySpeed: 6000,
			responsive: [
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 1,
					},
				},
			],
		});
		$(".whats-on_insta-feed-slider").slick({
			slidesToShow: 2,
			initialSlide: 0,
			slidesToScroll: 1,
			adaptiveHeight: false,
			arrows: false,
			dots: false,
			autoplay: true,
			autoplaySpeed: 6000,
			responsive: [
				{
					breakpoint: 1080,
					settings: {
						slidesToShow: 1,
					},
				},
			],
		});

		$(".slider-single-item--arrows").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			fade: true,
			autoplay: true,
			autoplaySpeed: 5000,
			speed: 200,
			cssEase: "cubic-bezier(0,-0.37, 0.3, 0.69)",
		});
		/**
		 * Gallery slider with thumbnails navigation.
		 */
		// var status = $('.gallery-slider__full-nav .pages');
		// var slickElement = $('.gallery-slider__full');

		// slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
		//     //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
		//     var i = (currentSlide ? currentSlide : 0) + 1;
		//     status.text(i + ' of ' + slick.slideCount);
		// });

		$(".gallery-slider__full").slick({
			slidesToShow: 2,
			slidesToScroll: 1,
			swipeToSlide: true,
			dots: false,
			arrows: true,
			infinite: true,
			initialSlide: 2,
		});

		$(".image-carousel-block-slider").slick({
			slidesToShow: 2,
			slidesToScroll: 1,
			swipeToSlide: true,
			dots: false,
			arrows: true,
			infinite: true,
			nextArrow: $(`.image-carousel-block-slider-next`),
			prevArrow: $(`.image-carousel-block-slider-prev`),
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
					},
				},
			],
		});

		// $('.gallery-slider__thumb').slick({
		//     dots: false,
		//     arrows: true,
		//     slidesToShow: 4,
		//     // centerMode: true,
		//     focusOnSelect: true,
		//     asNavFor: '.gallery-slider__full',
		// });

		// if($('.process-slider').length) {
		// 	var slide = $('.process-slider').find($('.slick-slide'));
		// 	console.log(slide);
		// 	slide.each(function() {
		// 		console.log($(this).children());
		// 		$(this).children().addClass('slide-child');
		// 	});

		// }
	}

	// Calculate the heighest slide and set a top/bottom margin for other children.
	// As variableHeight is not supported yet: https://github.com/kenwheeler/slick/issues/1803
	// var maxHeight = -1;
	// $('.slick-slide').each(function() {
	//     maxHeight = $(this).height();

	// 	$(this).closest($('.slick-list')).css("maxHeight", maxHeight);
	// });

	magnific: {
		// IFRAME

		$(".magnific-video").magnificPopup({
			type: "iframe",
			mainClass: "mfp-fade",
			removalDelay: 160,
			preloader: false,
			fixedContentPos: false,

			iframe: {
				markup:
					'<div class="mfp-iframe-scaler">' +
					'<div class="mfp-close"></div>' +
					'<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>' +
					"</div>",
				patterns: {
					youtu: {
						index: "youtu.be",
						id: function (url) {
							// Capture everything after the hostname, excluding possible querystrings.
							var m = url.match(/^.+youtu.be\/([^?]+)/);

							if (null !== m) {
								return m[1];
							}

							return null;
						},
						// Use the captured video ID in an embed URL.
						// Add/remove querystrings as desired.
						src: "//www.youtube.com/embed/%id%?autoplay=1&rel=0",
					},
					youtube: {
						index: "youtube.com/",
						id: "v=",
						src: "https://www.youtube.com/embed/%id%?autoplay=1",
					},
				},
			},
		});
		$(".popup-video").magnificPopup({
			//disableOn: 700,
			type: "iframe",
			iframe: {
				markup:
					'<div class="mfp-iframe-scaler">' +
					'<div class="mfp-close"></div>' +
					'<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>' +
					"</div>",
				patterns: {
					youtube: {
						index: "youtube.com/",
						id: "v=",
						src: "https://www.youtube.com/embed/%id%?autoplay=1",
					},
				},
			},
			mainClass: "mfp-fade",
			removalDelay: 160,
			preloader: false,

			fixedContentPos: false,
		});

		// INLINE
		$(".magnific-inline-popup").magnificPopup({
			type: "inline",

			fixedContentPos: false,
			// fixedBgPos: true,

			overflowY: "hidden",

			closeBtnInside: true,
			preloader: false,

			midClick: true,
			removalDelay: 300,
			mainClass: "my-mfp-zoom-in",
			callbacks: {
				open: function () {
					jQuery("body").addClass("noscroll");
				},
				close: function () {
					jQuery("body").removeClass("noscroll");
				},
			},
		});
		// Full screen popup
		$(".magnific-fullscreen-popup").magnificPopup({
			type: "inline",
			closeMarkup:
				'<button title="%title%" type="button" class="mfp-close"><span>Close</span></button>',
			fixedContentPos: false,
			// fixedBgPos: true,

			overflowY: "hidden",

			closeBtnInside: true,
			preloader: false,

			midClick: true,
			removalDelay: 300,
			mainClass: "my-mfp-zoom-in",
			callbacks: {
				open: function () {
					jQuery("body").addClass("noscroll");
				},
				close: function () {
					jQuery("body").removeClass("noscroll");
				},
			},
		});
		// console.log('hello');

		$(".js__home-popup").magnificPopup({
			type: "inline",

			fixedContentPos: false,
			fixedBgPos: true,

			overflowY: "auto",

			closeBtnInside: true,
			preloader: false,

			midClick: true,
			removalDelay: 300,
			mainClass: "mfp-fade",
		});

		// GALLERY SINGLE IMAGE
		$(".magnific-gallery-single-image").magnificPopup({
			type: "image",
			closeOnContentClick: true,
			mainClass: "mfp-img-single",
			image: {
				verticalFit: true,
				titleSrc: function titleSrc(item) {
					return item.el.attr("title");
				},
			},
			gallery: {
				enabled: false,
			},
		});

		// GALLERY IMAGE
		$(".magnific-gallery-image").magnificPopup({
			type: "image",
			closeOnContentClick: true,
			mainClass: "mfp-img-mobile",
			image: {
				verticalFit: true,
				titleSrc: function (item) {
					return item.el.attr("title");
				},
			},
			gallery: {
				enabled: true,
			},
		});
	}
	readmore: {
		$(".show-read-more").each(function () {
			var myStr = $(this).text();
			// var maxLength = myStr.indexOf('<div class="more-text">')

			// if(maxLength > 0) {
			// if($.trim(myStr).length > maxLength){
			// 	var newStr = myStr.substring(0, maxLength);
			// 	var removedStr = myStr.substring(maxLength, myStr.length);
			// 	$(this).empty().html(newStr);

			// 	var strippedHtml = removedStr.replace(/<[p^>]+>|<p>|<\/p>/g, '');
			// 	console.log(strippedHtml);
			// 	$(this).append('<p class="more-text">' + strippedHtml + '</p>');
			if ($("body").hasClass("page-template-tpl-process")) {
				$(this).append(
					' <a href="javascript:void(0);" class="read-more">Key Dates</a>'
				);
			} else {
				$(this).append(
					' <a href="javascript:void(0);" class="read-more">Read More</a>'
				);
			}

			// }
			// }
		});

		$(".read-more").on("click", function () {
			$(this).toggleClass("spin-arrow");
			$(this).siblings(".more-text").toggleClass("show");
			if ($("body").hasClass("page-template-tpl-process")) {
				$(this).html() == "Hide"
					? $(this).html("Key Dates")
					: $(this).html("Hide");
			} else {
				$(this).html() == "Read Less"
					? $(this).html("Read More")
					: $(this).html("Read Less");
			}
		});
	}
	accordion: {
		if ($(".block--accordion").length) {
			$(".accordion__content--wrapper").css("display", "none"); // All items closed.

			$(".block--accordion")
				.find(".js-accordion-trigger")
				.on("click", function () {
					// Close only the items in this accordion.
					$(this)
						.parent()
						.siblings()
						.find(".js-accordion-trigger")
						.next()
						.slideUp("fast"); // reset
					$(this)
						.parent()
						.siblings()
						.find(".js-accordion-trigger")
						.removeClass("open"); // reset

					// Close items in all accordions on the page.
					//$('.js-accordion-trigger').not(this).next().slideUp('fast'); // reset
					//$('.js-accordion-trigger').not(this).removeClass('open');    // reset

					if ($(this).hasClass("open")) {
						$(this).next().slideUp("fast");
						$(this).removeClass("open");
					} else {
						$(this).next().slideDown("fast");
						$(this).addClass("open");
						// $('html, body').animate({
						// 	scrollTop: $(this).offset().top - 200
						// }, 500);
					}
				});
		}
	}

	tabs: {
		const tabs = document.querySelectorAll('[role="tab"]');
		const tabList = document.querySelector('[role="tablist"]');

		if (tabs) {
			// Add a click event handler to each tab
			tabs.forEach((tab) => {
				if (!tab.closest('[role="tablist"]').classList.contains("slick-dots")) {
					tab.addEventListener("click", changeTabs);
				}
			});

			// Enable arrow navigation between tabs in the tab list
			let tabFocus = 0;

			if (tabList) {
				tabList.addEventListener("keydown", (e) => {
					// Move right
					if (e.keyCode === 39 || e.keyCode === 37) {
						tabs[tabFocus].setAttribute("tabindex", -1);
						if (e.keyCode === 39) {
							tabFocus++;
							// If we're at the end, go to the start
							if (tabFocus >= tabs.length) {
								tabFocus = 0;
							}
							// Move left
						} else if (e.keyCode === 37) {
							tabFocus--;
							// If we're at the start, move to the end
							if (tabFocus < 0) {
								tabFocus = tabs.length - 1;
							}
						}

						tabs[tabFocus].setAttribute("tabindex", 0);
						tabs[tabFocus].focus();
					}
				});
			}

			function changeTabs(e) {
				e.preventDefault();
				const target = e.target;
				const parent = target.parentNode;
				const grandparent = parent.closest(".tabs");
				setTimeout(function () {
					$(".js-process-slider").slick("refresh");
				}, 100);

				// console.info(grandparent);
				// console.info(parent);
				// Remove all current selected tabs
				grandparent
					.querySelectorAll('[aria-selected="true"]')
					.forEach((t) => t.setAttribute("aria-selected", false));

				// Set this tab as selected
				target.setAttribute("aria-selected", true);

				// Hide all tab panels
				grandparent
					.querySelectorAll('[role="tabpanel"]')
					.forEach((p) => p.setAttribute("hidden", true));

				// Remove 'active' class on all tab panels
				grandparent
					.querySelectorAll('[role="tabpanel"]')
					.forEach((q) => q.classList.remove("active"));

				// Remove 'active' class on all tabs
				grandparent
					.querySelectorAll('[role="presentation"]')
					.forEach((r) => r.classList.remove("active"));

				// Show the selected panel
				console.info(target.getAttribute("aria-controls"));
				console.info(grandparent);
				console.info(grandparent.parentNode);
				grandparent.parentNode
					.querySelector(`#${target.getAttribute("aria-controls")}`)
					.removeAttribute("hidden");

				// Add 'active' class on selected tab and tab panel
				grandparent.parentNode
					.querySelector(`#${target.getAttribute("aria-controls")}`)
					.classList.add("active");
				parent.classList.add("active");
			}
		}

		if (window.location.href.indexOf("#tab-") > -1) {
			var urlTab = window.location.href.substring(
				window.location.href.indexOf("#tab-")
			);
			const target = document.querySelector("a[href='" + urlTab + "']");
			console.dir("target: " + target);
			const parent = target.parentNode;
			const grandparent = parent.closest(".tabs");
			// console.log(grandparent);
			setTimeout(function () {
				$(".js-process-slider").slick("refresh");
			}, 400);

			grandparent
				.querySelectorAll('[aria-selected="true"]')
				.forEach((t) => t.setAttribute("aria-selected", false));
			// Set this tab as selected
			target.setAttribute("aria-selected", true);
			// Hide all tab panels
			grandparent
				.querySelectorAll('[role="tabpanel"]')
				.forEach((p) => p.setAttribute("hidden", true));
			// Remove 'active' class on all tab panels
			grandparent
				.querySelectorAll('[role="tabpanel"]')
				.forEach((q) => q.classList.remove("active"));
			// Remove 'active' class on all tabs
			grandparent
				.querySelectorAll('[role="presentation"]')
				.forEach((r) => r.classList.remove("active"));
			// Show the selected panel
			// console.info(target.getAttribute("aria-controls"));
			// console.info(grandparent);
			// console.info(grandparent.parentNode);
			grandparent.parentNode
				.querySelector(`#${target.getAttribute("aria-controls")}`)
				.removeAttribute("hidden");

			// Add 'active' class on selected tab and tab panel
			grandparent.parentNode
				.querySelector(`#${target.getAttribute("aria-controls")}`)
				.classList.add("active");
			parent.classList.add("active");
		}

		if ($(".js-inline-tab-changer").length) {
			$(".js-inline-tab-changer").on("click", function (e) {
				e.preventDefault();
				console.log($(this));
				const target = document.getElementById($(this).attr("data-tab-open"));
				console.log(target);
				const parent = target.parentNode;
				const grandparent = parent.closest(".tabs");
				setTimeout(function () {
					$(".js-process-slider").slick("refresh");
				}, 100);
				// Remove all current selected tabs
				grandparent
					.querySelectorAll('[aria-selected="true"]')
					.forEach((t) => t.setAttribute("aria-selected", false));

				// Set this tab as selected
				target.setAttribute("aria-selected", true);

				// Hide all tab panels
				grandparent
					.querySelectorAll('[role="tabpanel"]')
					.forEach((p) => p.setAttribute("hidden", true));

				// Remove 'active' class on all tab panels
				grandparent
					.querySelectorAll('[role="tabpanel"]')
					.forEach((q) => q.classList.remove("active"));

				// Remove 'active' class on all tabs
				grandparent
					.querySelectorAll('[role="presentation"]')
					.forEach((r) => r.classList.remove("active"));

				// Show the selected panel
				console.info(target.getAttribute("aria-controls"));
				console.info(grandparent);
				console.info(grandparent.parentNode);
				grandparent.parentNode
					.querySelector(`#${target.getAttribute("aria-controls")}`)
					.removeAttribute("hidden");

				// Add 'active' class on selected tab and tab panel
				grandparent.parentNode
					.querySelector(`#${target.getAttribute("aria-controls")}`)
					.classList.add("active");
				parent.classList.add("active");
			});
		}
	}

	iframes: {
		$('iframe[src*="vimeo.com"]').wrap('<div class="iframe-wrapper"></div>');
		$('iframe[src*="youtube.com"]').wrap('<div class="iframe-wrapper"></div>');
	}

	loadingAnimations: {
		$.fn.isOnScreen = function () {
			var win = $(window);
			var viewport = {
				top: win.scrollTop(),
			};
			viewport.bottom = viewport.top + win.height() - 80;

			var bounds = this.offset();
			bounds.bottom = bounds.top + this.outerHeight();

			return !(viewport.bottom < bounds.top || viewport.top > bounds.bottom);
		};

		// First load Animation
		$(".off-screen").each(function (index) {
			if ($(this).isOnScreen()) {
				$(this).removeClass("off-screen--hide");
			}
		});

		// Animation on scroll
		$(window).scroll(function () {
			$(".off-screen").each(function (index) {
				if ($(this).isOnScreen()) {
					$(this).removeClass("off-screen--hide");
				}
			});
		});
	}
	// $( window ).on('load', function() {
	menu: {
		$(".site-menu-wrapper .top-nav__hamburger").on("click", function () {
			console.log("open=menu");
			$(".site-menu-wrapper").toggleClass("menu-open");
			$(".button--hamburger").toggleClass("open");
			$("body").toggleClass("no-scroll");

			var parentID = $(".site-menu-wrapper")
				.find($(".menu-item-has-children.current-page-ancestor"))
				.attr("id");

			if ($("body").hasClass("page-child")) {
				// console.log($( '.site-menu-wrapper' ).find($('.menu-item-has-children.current-page-ancestor')));
				setTimeout(function () {
					$(".site-menu-wrapper")
						.find($(".menu-item-has-children.current-page-ancestor"))
						.addClass("sub-menu-open");
					$(".sub-menu-container")
						.find($(".sub-" + parentID))
						.addClass("sub-menu-open");
					$(".site-menu__lhs").addClass("menu-open--zindex");
					$(".sub-menu-container").addClass("mobile-z-index");
					$(".site-menu-wrapper > .site-menu").addClass("no-scroll"); // reset
				}, 600);
			}

			// MENU and CLOSE text
			$(".top-nav__hamburger #menu-text").css("transition", "all .4s ease-out");

			if ($(".site-menu-wrapper").hasClass("menu-open")) {
				$(".top-nav__hamburger #menu-text").text("").css("opacity", "0");

				setTimeout(function () {
					$(".top-nav__hamburger #menu-text")
						.text("CLOSE")
						.css("opacity", "1")
						.css("font-size", "11px")
						.css("margin-top", "2px")
						.css("margin-left", "-1px");
				}, 400);
			} else {
				$(".top-nav__hamburger #menu-text").text("").css("opacity", "0");

				setTimeout(function () {
					$(".top-nav__hamburger #menu-text")
						.text("MENU")
						.css("opacity", "1")
						.css("font-size", "");
				}, 400);
			}

			// Menu closes on click outside.
			let menu = $(".site-menu-wrapper");

			if (menu.hasClass("menu-open")) {
				$(document.body).on("click", function (e) {
					if (!menu.is(e.target) && menu.has(e.target).length === 0) {
						//console.log('NOT the menu');

						$(".site-menu-wrapper").removeClass("menu-open");
						$("body").removeClass("noscroll");

						$(document.body).off("click");
					}
				});
			}
		});

		// When click on a submenu page:
		// Stop default and close the menu
		// Fade out the page and go to page clicked.

		// $( '.site-menu a' ).on( 'click', function(e){

		// 	e.preventDefault();
		// 	console.log('menu-clicked');
		// 	const targetURL = $(this).attr('href');

		// 	$( '.site-menu-wrapper' ).removeClass( 'menu-open' );
		// 	$( 'body' ).removeClass( 'noscroll' );

		// 	if ( targetURL.includes( php_vars.homeUrl ) ){
		// 		// Internal link, so we change the page.

		// 		setTimeout( function(){
		// 			$('html, body').animate({
		// 				opacity: 0
		// 			}, 500);
		// 		}, 400 );

		// 		setTimeout( function(){
		// 			window.location = targetURL;
		// 		}, 900 );
		// 	}

		// } );
	}

	fixedheader: {
		$(function () {
			$(window).scroll(function () {
				if ($(window).scrollTop() >= 150) {
					$(".top-nav").addClass("fixed-header");
				}
				if ($(window).scrollTop() >= 190) {
					$(".top-nav").addClass("transform-none");
				} else {
					$(".top-nav").removeClass("fixed-header");
					$(".top-nav").removeClass("transform-none");
				}
			});
		});
	}
	columnMenus: {
		var menuContainer = $(".menu-main-menu-container");
		var mainMenu = $("#main-menu");
		var menuItem = mainMenu.find($(".menu-item-has-children"));
		var toInsert = $('<div class="site-menu__list-arrow"></div>');

		function columnMenu() {
			menuContainer
				.find($("li.menu-item a"))
				.addClass("d-flex site-menu__list-link");
			menuItem.find("> a").append(toInsert);
		}

		columnMenu();
	}
	// });

	// make every second word ghost lettering
	var toGhost = $(".ghost-word");

	// console.log(toGhost);
	for (var i = 0; i < toGhost.length; ++i) {
		ghostSecondWord(toGhost[i], "font-ghost");
	}

	function ghostSecondWord(elem, className) {
		elem.innerHTML = elem.textContent.replace(/\w+ (\w+)/, function (s, c) {
			return s.replace(c, '<span class="' + className + '">' + c + "</span>");
		});
	}

	var sixthFormGhost = $(".parent-pageid-50050 .ghost-word");
	for (var i = 0; i < toGhost.length; ++i) {
		ghostSecondWord(toGhost[i], "white-highlight dark-grey-text");
	}

	// move sub menu menu items into new div
	var subMenuList = document.querySelectorAll(".sub-menu");
	var subMenuContainer = $(".sub-menu-container");

	function moveSubMenu() {
		$(".sub-menu").each(function () {
			$(this).appendTo(".sub-menu-container");
		});
	}
	moveSubMenu();

	//change search value

	function changeSearch() {
		$(".search-submit").replaceWith(
			'<button class="search-submit" type="submit"></button>'
		);
	}
	changeSearch();

	if ($("body").hasClass("home")) {
		// $(window).on('beforeunload', function() {
		// 	$(window).scrollTop(0);
		// });
		preloader();
		homeBannerCanvas();
	}

	function searchFilterRow() {
		$(".search-filter-results").addClass("row");
	}
	searchFilterRow();

	//GSAP Animations
	workwithUs: {
		function expandingVideo() {
			var block = $(".block--expanding-video");
			var title = block.find(".js-expanding-video-title");
			var video = block.find(".js-expanding-video-video");
			var videoContainer = block.find(".js-expanding-video-video-container");
			var scrollSpeed = 2;
			let titleWidth = title.width();
			console.log($(window).innerHeight());
			let videoWidth = video.width();
			let videoHeight = video.height();
			let blockHeight = block.height();
			let xMove;
			if ($(window).innerWidth() < 768) {
				xMove = -1 * titleWidth;
			} else {
				xMove = -0.75 * titleWidth;
			}
			gsap.to(title, {
				x: xMove,
				scrollTrigger: {
					scrub: 1,
					trigger: video,
					// start: `top bottom-=${$(window).innerHeight()/10}`,
					start: "top bottom",
				},
			});

			gsap.to(video, {
				duration: 5,
				scale: "1",
				ease: "linear",
				scrollTrigger: {
					trigger: video,
					start: "bottom bottom-=100px",
					scrub: 1,
					pin: true,
					pinSpacing: true,
					// markers: true,
					end: "+=800",

					// markers: true,
				},
			});
		}
		if ($(".block--expanding-video").length) {
			if (window.innerWidth > 768) {
				expandingVideo();
			}
		}
	}
	loadingAnimations: {
		function loadingAnimations() {
			$(".js-animate-block").each(function () {
				let trigger = $(this);
				let title = $(this).find($(".js-animate-title"));
				let picture = $(this).find($(".js-animate-image"));
				let quote = $(this).find($(".js-animate-quote"));
				let popup = $(this).find($(".js__home-popup"));
				let slideLeft = $(this).find($(".js-slide-left"));
				let videoCircle = $(this).find($(".js-animate-circle"));

				var tl = gsap.timeline({
					// ease: "power3.inOut",
					scrollTrigger: {
						trigger: trigger,
						markers: false,
						start: "top center+=260px",
						scrub: false,
						ease: "power2.out",
					},
				});

				if (picture.length) {
					tl.from(picture, {
						opacity: 0,
						duration: 0.8,
					});
				}
				if (title.length) {
					tl.from(
						title,
						{
							opacity: 0,
							y: 100,
							duration: 0.8,
						},
						"<"
					);
				}
				if (popup.length) {
					tl.from(popup, {
						duration: 0.8,
						// stagger: 0.5,
						opacity: 0,
					});
				}
				if (quote.length) {
					tl.from(
						quote,
						{
							opacity: 0,
							duration: 0.9,
						},
						"<+0.1"
					);
				}
				if (slideLeft.length) {
					tl.from(
						slideLeft,
						{
							x: 100,
							stagger: 0.4,
							opacity: 0,
							duration: 0.4,
						},
						">"
					);
				}
				if (videoCircle.length) {
					tl.from(
						videoCircle,
						{
							opacity: 0,
							stagger: {
								each: 0.15,
								from: "end",
								// grid: "auto"
							},
							// duration: 2.25,
						},
						">"
					);
				}
			});

			$(".js-banner").each(function () {
				let trigger = $(this);
				let title = $(this).find($(".js-animate-title"));
				let image = $(this).find($(".js-animate-image"));

				var tl = gsap.timeline({
					ease: "power3.inOut",
				});
				if (image.length) {
					tl.from(image, {
						opacity: 0,
						duration: 0.7,
					});
				}
				if (title.length) {
					tl.from(
						title,
						{
							opacity: 0,
							// y: 100,
							duration: 0.5,
						},
						"<"
					);
				}
			});
		}
		loadingAnimations();

		if ($("body").hasClass("page-template-tpl-work-with-us")) {
			function wwuImages() {
				const wwuImageTl = gsap.timeline({
					paused: false,
					delay: 0.9,
				});

				let imgOne = $(".banner-image-1");
				let content = $(".js-animate-content");
				let cover = $(".js-banner-cover");
				let scroll = $(".js-wwu-scroll");
				let scrollText = $(".js-wwu-scroll span");

				wwuImageTl
					.fromTo(
						imgOne,
						{
							scale: 5,
							rotate: 28,
							autoAlpha: 0,
						},
						{
							scale: 1,
							rotate: 0,
							autoAlpha: 1,
							duration: 1.9,
							ease: "power2.inOut",
						},
						""
					)
					.to(
						cover,
						{
							opacity: 0,
							duration: 0.6,
							ease: "power4.out",
						},
						">-=0.3"
					)
					.to(
						content,
						{
							autoAlpha: 1,
							duration: 0.6,
							ease: "power4.out",
						},
						"<"
					)
					.to(
						scroll,
						{
							autoAlpha: 1,
							scale: 1,
							duration: 0.8,
							ease: "power4.out",
						},
						"<"
					)
					.to(
						scrollText,
						{
							autoAlpha: 1,
							duration: 0.3,
							ease: "power4.out",
						},
						">-=0.4"
					);
			}
			wwuImages();
		}
	}

	communityPartnerships: {
		function communityAnimations() {
			$(".js-animate-community").each(function () {
				let trigger = $(this);
				let title = $(this).find($(".js-animate-community-title p"));
				let image = $(this).find($(".js-animate-community-image"));
				let intro = $(this).find($(".js-animate-community-intro p"));
				let brochureImage = $(this).find(
					$(".js-animate-community-brochure-image")
				);
				let brochureDetails = $(this).find(
					$(".js-animate-community-brochure-details")
				);
				let partnershipBoxes = $(this).find(
					$(".js-animate-community-partnerships-box")
				);

				var tl = gsap.timeline({
					ease: "power3.inOut",
					scrollTrigger: {
						trigger: trigger,
						start: "top bottom-=300",
					},
				});
				if (title.length) {
					tl.from(
						title,
						{
							// opacity: 0,
							yPercent: 100,
							duration: 1.2,
							stagger: 0.2,
						},
						"<"
					);
				}
				if (image.length) {
					tl.from(
						image,
						{
							opacity: 0,
							scale: 0,
							duration: 0.9,
							stagger: 0.2,
						},
						"<"
					);
				}
				if (intro.length) {
					tl.from(
						intro,
						{
							// opacity: 0,
							yPercent: 100,
							duration: 1.2,
							stagger: 0.2,
						},
						"<"
					);
				}
				if (brochureImage.length) {
					tl.from(
						brochureImage,
						{
							rotate: "20deg",
							duration: 1.2,
							scale: 0,
							stagger: 0.2,
						},
						">"
					);
				}
				if (brochureDetails.length) {
					tl.from(
						brochureDetails,
						{
							opacity: 0,
							duration: 1.2,
							stagger: 0.2,
						},
						"<"
					);
				}
				if (partnershipBoxes.length) {
					tl.from(
						partnershipBoxes,
						{
							// opacity: 0,
							duration: 0.8,
							// rotate: "143deg",
							scale: 0,
							stagger: 0.4,
						},
						"<"
					);
				}
			});
		}
		if ($("body").hasClass("page-template-tpl-community-partnerships")) {
			console.log("community");
			communityAnimations();
		}
	}

	buttonEffects: {
		// Get width of button and round up to nearest 100
		$(".button, .custom-link--bookworm, .custom-link--math-wizard").each(
			function () {
				var buttonWidth = $(this).outerWidth();
				var buttonWidthRounded = Math.ceil(buttonWidth / 100) * 100;

				// Add wrappers to make hover function work
				$(this).wrap(
					'<div class="button-wrapper button-width-' +
						buttonWidthRounded +
						'"></div>'
				);
				$(this).wrapInner('<span class="button--text"></span>');
				$(".button-wrapper").prepend('<span class="button--circle"></span>');

				// Hover effect
				$(this).mouseenter(function (e) {
					var parentOffset = $(this).offset();
					var relX = e.pageX - parentOffset.left;
					var relY = e.pageY - parentOffset.top;

					$(this).prev(".button--circle").css({ left: relX, top: relY });
					$(this).prev(".button--circle").removeClass("implode-circle");
					$(this).prev(".button--circle").addClass("explode-circle");
				});

				$(this).mouseleave(function (e) {
					var parentOffset = $(this).offset();
					var relX = e.pageX - parentOffset.left;
					var relY = e.pageY - parentOffset.top;

					$(this).prev(".button--circle").css({ left: relX, top: relY });
					$(this).prev(".button--circle").removeClass("explode-circle");
					$(this).prev(".button--circle").addClass("implode-circle");
				});
			}
		);
		$(".button-purple").each(function () {
			// Hover effect
			$(this).mouseenter(function (e) {
				var parentOffset = $(this).offset();
				var relX = e.pageX - parentOffset.left;
				var relY = e.pageY - parentOffset.top;

				$(this).prev(".button--circle").css({ left: relX, top: relY });
				$(this).prev(".button--circle").removeClass("implode-circle--purple");
				$(this).prev(".button--circle").addClass("explode-circle--purple");
			});

			$(this).mouseleave(function (e) {
				var parentOffset = $(this).offset();
				var relX = e.pageX - parentOffset.left;
				var relY = e.pageY - parentOffset.top;

				$(this).prev(".button--circle").css({ left: relX, top: relY });
				$(this).prev(".button--circle").removeClass("explode-circle--purple");
				$(this).prev(".button--circle").addClass("implode-circle--purple");
			});
		});
		$(".button-dark-grey-ghost").each(function () {
			// Hover effect
			$(this).mouseenter(function (e) {
				var parentOffset = $(this).offset();
				var relX = e.pageX - parentOffset.left;
				var relY = e.pageY - parentOffset.top;

				$(this).prev(".button--circle").css({ left: relX, top: relY });
				$(this).prev(".button--circle").removeClass("implode-circle--grey");
				$(this).prev(".button--circle").addClass("explode-circle--grey");
			});

			$(this).mouseleave(function (e) {
				var parentOffset = $(this).offset();
				var relX = e.pageX - parentOffset.left;
				var relY = e.pageY - parentOffset.top;

				$(this).prev(".button--circle").css({ left: relX, top: relY });
				$(this).prev(".button--circle").removeClass("explode-circle--grey");
				$(this).prev(".button--circle").addClass("implode-circle--grey");
			});
		});

		$(".btn").each(function () {
			// Get width of button and round up to nearest 100
			var btnWidth = $(this).outerWidth();
			var btnWidthRounded = Math.ceil(btnWidth / 100) * 100;

			// Add wrappers to make hover function work
			$(this).wrap(
				'<div class="button-wrapper button-width-' +
					btnWidthRounded +
					'"></div>'
			);
			$(this).wrapInner('<span class="button--text"></span>');
			$(".button-wrapper").prepend('<span class="button--circle"></span>');

			// Hover effect
			$(this).mouseenter(function (e) {
				var parentOffset = $(this).offset();
				var relX = e.pageX - parentOffset.left;
				var relY = e.pageY - parentOffset.top;

				$(this).prev(".button--circle").css({ left: relX, top: relY });
				$(this).prev(".button--circle").removeClass("implode-circle");
				$(this).prev(".button--circle").addClass("explode-circle");
			});

			$(this).mouseleave(function (e) {
				var parentOffset = $(this).offset();
				var relX = e.pageX - parentOffset.left;
				var relY = e.pageY - parentOffset.top;

				$(this).prev(".button--circle").css({ left: relX, top: relY });
				$(this).prev(".button--circle").removeClass("explode-circle");
				$(this).prev(".button--circle").addClass("implode-circle");
			});
		});

		// https://codepen.io/animationbro/details/dyoVQRz
	}
	instagramCarousel: {
		$(".insta-feed").slick({
			slidesToShow: 6,
			slidesToScroll: 1,
			dots: false,
			centerMode: true,
			pauseOnHover: true,
			autoplay: true,
			autoplaySpeed: 2000,
			responsive: [
				{
					breakpoint: 1500,
					settings: {
						slidesToShow: 4,
					},
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 2,
					},
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
					},
				},
			],
		});
	}
	homeInstagramCarousel: {
		// $('.whats-on_insta-reel').slick({
		// 	slidesToShow: 1,
		// 	slidesToScroll: 1,
		// 	dots: true,
		// 	pauseOnHover: true,
		// 	autoplay: true,
		// 	autoplaySpeed: 2000,
		// });
	}
	meetTheTeamSlider: {
		$(".meet-the-team-slider").slick({
			slidesToShow: 2,
			slidesToScroll: 1,
			dots: false,
			infinite: false,
			nextArrow: ".next-arrow",
			prevArrow: ".prev-arrow",
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
					},
				},
			],
		});
	}

	if ($(".content-padding").has("table").length) {
		$(".content-padding")
			.find("table")
			.wrap('<div class="table-wrapper"></div>');
	}
});

/**
 * jQuery Plugin: Sticky Tabs
 *
 * @author Aidan Lister <aidan@php.net>
 * @version 1.2.0
 */
//  (function ( $ ) {
//     $.fn.stickyTabs = function( options ) {
//         var context = this

//         var settings = $.extend({
//             getHashCallback: function(hash, btn) { return hash },
//             selectorAttribute: "href",
//             backToTop: false,
//             initialTab: $('li.active > a', context)
//         }, options );

//         // Show the tab corresponding with the hash in the URL, or the first tab.
//         var showTabFromHash = function() {
//           var hash = settings.selectorAttribute == "href" ? window.location.hash : window.location.hash.substring(1);
//           if (hash != '') {
//               var selector = hash ? 'a[' + settings.selectorAttribute +'="' + hash + '"]' : settings.initialTab;
//               $(selector, context).tab('show');
//               setTimeout(backToTop, 1);
//           }
//         }

//         // We use pushState if it's available so the page won't jump, otherwise a shim.
//         var changeHash = function(hash) {
//           if (history && history.pushState) {
//             history.pushState(null, null, window.location.pathname + window.location.search + '#' + hash);
//           } else {
//             scrollV = document.body.scrollTop;
//             scrollH = document.body.scrollLeft;
//             window.location.hash = hash;
//             document.body.scrollTop = scrollV;
//             document.body.scrollLeft = scrollH;
//           }
//         }

//         var backToTop = function() {
//           if (settings.backToTop === true) {
//             window.scrollTo(0, 0);
//           }
//         }

//         // Set the correct tab when the page loads
//         showTabFromHash();

//         // Set the correct tab when a user uses their back/forward button
//         $(window).on('hashchange', showTabFromHash);

//         // Change the URL when tabs are clicked
//         $('a', context).on('click', function(e) {
//           var hash = this.href.split('#')[1];
//           if (typeof hash != 'undefined' && hash != '') {
//               var adjustedhash = settings.getHashCallback(hash, this);
//               changeHash(adjustedhash);
//               setTimeout(backToTop, 1);
//           }
//         });

//         return this;
//     };
// }( jQuery ));

// $('.nav-tabs').stickyTabs();
/****************************************************************************
 * Is on screen.
 ***************************************************************************/
$.fn.isOnScreen = function () {
	var win = $(window);

	var viewport = {
		top: win.scrollTop(),
	};
	viewport.bottom = viewport.top + win.height() - 300;

	var bounds = this.offset();
	bounds.bottom = bounds.top + this.outerHeight();

	return !(viewport.bottom < bounds.top || viewport.top > bounds.bottom);
};

$(".animate").each(function () {
	if ($(this).isOnScreen()) {
		$(this).addClass("visible");
	}
});
$("[data-aos]").each(function () {
	if ($(this).isOnScreen()) {
		$(this).addClass("aos-animate");
	}
});

$(document).scroll(function () {
	$(".animate").each(function () {
		if ($(this).isOnScreen()) {
			$(this).addClass("visible");
		}
	});
	$("[data-aos]").each(function () {
		if ($(this).isOnScreen()) {
			$(this).addClass("aos-animate");
		}
	});
});
